import { Module } from "vuex";

const themeModule: Module<any, any> = {
  state() {
    return {
      route: "",
      sideMenuShow: false,
      sideMenuOpen: process.env.VUE_APP_SIDE_MENU_OPEN,
      sideMenuWidth: process.env.VUE_APP_SIDE_MENU_WIDTH,
      toolbarShow: false,
    };
  },
  getters: {
    route: (state) => state.route,
    sideMenuShow: (state) => state.sideMenuShow,
    sideMenuOpen: (state) => state.sideMenuOpen,
    sideMenuWidth: (state) => state.sideMenuWidth,
    toolbarShow: (state) => state.toolbarShow,
  },
  mutations: {
    setRoute: (state, data) => {
      state.route = data;
    },
    setSideMenuShow: (state, data) => {
      state.sideMenuShow = data;
    },
    setSideMenuOpen: (state, data) => {
      state.sideMenuOpen = data;
    },
    setSideMenuWidth: (state, data) => {
      state.sideMenuWidth = data;
    },
    setToolbarShow: (state, data) => {
      state.toolbarShow = data;
    },
  },
};

export default themeModule;
