// eslint-disable
export const getImage = (image: string | null | undefined, isLocal = true) => {
  if (!image) {
    return require(`@/assets/icons/no-image.png`);
  }
  try {
    return !isLocal ? image : require(`@/assets/${image}`);
  } catch (error) {
    console.log("error", error);
    return null;
  }
};
