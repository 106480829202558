import { common } from "./common";
import { home } from "./home";
import { company } from "./company";
import { terms } from "./terms";

export const language = {
  translations: {
    common: common,
    home: home,
    terms: terms,
    company: company,
  },
};
