import router from "@/router";
import { resetWidowHeight } from "./";

export const goToLink = (url: string, external = false) => {
  resetWidowHeight();
  if (!external) router.push(url);
  else window.open(url, "_black");
};

export const checkRouter = (url: string) => {
  const currentRoute = router.currentRoute.value.path;
  return currentRoute === url;
};
