import { createStore } from "vuex";

import themeModule from "./modules/theme";

const store = createStore({
  modules: {
    themeModule,
  },
});

export default store;

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $store: typeof store;
  }
}
