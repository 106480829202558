import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// PAGES
import { HomePage, CompanyPage, PrivacyPolice, TermsOfUse } from "@/pages";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/company",
    name: "company",
    component: CompanyPage,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyPolice,
  },
  {
    path: "/termsofuse",
    name: "termsOfUse",
    component: TermsOfUse,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
