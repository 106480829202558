export const EvenOrOdd = (value: number) => {
  return value % 2 === 0 ? "even" : "odd";
};

export const scrollToTop = () => {
  document.documentElement.scrollTop = 0;
};

export const getWidowSize = () => {
  try {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  } catch (_) {
    return;
  }
};

export const setTitle = (value: string) => {
  try {
    document.title = value;
  } catch (_) {
    return;
  }
};

export const resetWidowHeight = () => {
  try {
    const bodyElement = document.querySelector("body") as HTMLBodyElement;
    bodyElement.style.maxHeight = "auto";
    bodyElement.style.overflow = "auto";
  } catch (_) {
    return;
  }
};

export const setFontSizeHtml = (value: string) => {
  try {
    const htmlElement = document.querySelector("html") as HTMLHtmlElement;
    const bodyElement = document.querySelector("body") as HTMLBodyElement;
    htmlElement.style.fontSize = value;
    bodyElement.style.fontSize = value;
  } catch (_) {
    return;
  }
};

export const setBodyBackground = (value: string) => {
  try {
    const bodyElement = document.querySelector("body") as HTMLBodyElement;
    bodyElement.style.background = value;
  } catch (_) {
    return;
  }
};

export const setFavIcon = (value: string) => {
  try {
    if (value) {
      const link = document.querySelector("link[rel~='icon']") as any;
      link.href = value;
    }
  } catch (_) {
    return;
  }
};
