import { useI18n } from "vue-i18n";
import { config } from "@/config/config";

const formatTextArray = (texts: any[]): string => {
  try {
    let div = "";
    texts.forEach((item: any) => {
      div += `<p>${item}</p>`;
    });
    return div;
  } catch (_) {
    return "";
  }
};

export const setLocale = (value: any) => {
  window.location.reload();
};

export const getTranslate = (text?: string, longText = false) => {
  try {
    const { t, tm } = useI18n();
    if (config.language.i18n) {
      if (longText) {
        const texts = Object(tm(text || "array_empty"));
        return formatTextArray(texts);
      } else return t(text || "empty");
    } else return text || "";
  } catch (_) {
    return "";
  }
};
