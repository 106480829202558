import { language as portugueseLanguage } from "./pt-br";
import { language as englishLanguage } from "./en-us";

const messages = {
  pt_br: {
    empty: "",
    array_empty: [],
    ...portugueseLanguage.translations,
  },
  en_us: {
    empty: "",
    array_empty: [],
    ...englishLanguage.translations,
  },
};

export { messages };
